import { assert, hasValue, isNotBlank } from 'ajax-form-submit/js-utils'
import {
  querySelector,
  elementIs,
  hasClass,
  addClass,
  removeClass,
  showElements,
  hideElements,
  registerEvent,
  registerMutationObserver
} from 'ajax-form-submit/js-dom-utils'

import { createDatasetHelper } from 'ajax-form-submit/js-dataset-helper'

const PREFIX = 'bp'
const CLASS_NAME = 'pagination'
const INIT_CLASS_NAME = `${CLASS_NAME}-initialized`
const NUMBER_CLASS_NAME = 'page-number'
const ACTIVE_CLASS_NAME = 'active'
const DISABLED_CLASS_NAME = 'disabled'
const DEFAULT_CONFIG = {
  'nav-class': '',
  'button-class': '',
  'number-button-class': '',
  'arrow-button-class': '',
  'first-label': '<<',
  'prev-label': '<',
  'next-label': '>',
  'last-label': '>>',
}

const eventUpdateName = 'ajax-form-submit-event-page-update'

class BootstrapPagination {
  
  static config = {
    'nav-class': '',
    'button-class': '',
    'number-button-class': '',
    'arrow-button-class': '',
    'first-label': '',
    'prev-label': '',
    'next-label': '',
    'last-label': '',
  }

  constructor(el, onPaging, opt = {}) {
    assert(elementIs(el, 'div'), 1, 'div')
    assert(hasClass(el, CLASS_NAME), 'Argument 1 must has class "pagination"')
    assert(!hasClass(el, INIT_CLASS_NAME), 'Argument 1 was initialized')

    this.root = el
    this.datasetHelper = createDatasetHelper(PREFIX)

    this.size = 0
    this.totalElement = 0
    this.totalPage = 0
    this.currentPage = 0;
    this.startPage = 0;
    this.endPage = 0;
    this.onPaging = onPaging

    hideElements(this.root)

    const navClass = this._getSetting(el, 'nav-class')
    const firstLabel = this._getSetting(el, 'first-label')
    const prevLabel = this._getSetting(el, 'prev-label')
    const nextLabel = this._getSetting(el, 'next-label')
    const lastLabel = this._getSetting(el, 'last-label')
    const anchor = this._getSetting(el, 'anchor')
    const buttonClass = this._getSetting(el, 'button-class')
    const numberButtonClass = this._getSetting(el, 'number-button-class')
    const arrowButtonClass = this._getSetting(el, 'arrow-button-class')
    const arrowClass = `${buttonClass} ${arrowButtonClass}`
    this._buttonClass = `${buttonClass} ${numberButtonClass}`

    if (isNotBlank(anchor)) {
      this._anchor = querySelector(`#${anchor}`)[0]?.offsetTop
    }

    this.firstElem = createButton(firstLabel, arrowClass)
    this.prevElem = createButton(prevLabel, arrowClass)
    this.nextElem = createButton(nextLabel, arrowClass)
    this.lastElem = createButton(lastLabel, arrowClass)

    registerEvent(this.firstElem, 'click',
      event => this.update(event, 0, this.size))
    registerEvent(this.prevElem, 'click',
      event => this.update(event, this.currentPage - 1, this.size))
    registerEvent(this.nextElem, 'click',
      event => this.update(event, this.currentPage + 1, this.size))
    registerEvent(this.lastElem, 'click',
      event => this.update(event, this.totalPage, this.size))
    
    const nav = createNav(navClass)
    el.append(nav)
    nav.append(this.firstElem)
    nav.append(this.prevElem)
    nav.append(this.nextElem)
    nav.append(this.lastElem)

    registerEvent(el, eventUpdateName, event => {
      this.onPaging = event.detail.onPaging
      this.setPage(event.detail.page)
    })

    addClass(el, INIT_CLASS_NAME)
  }

  setPage(pageProp) {
    this.size = pageProp.size
    this.totalElement = pageProp.totalElements
    this.totalPage = pageProp.totalPages - 1 || 0
    this.currentPage = pageProp.number
    this.startPage = Math.max(0, this.currentPage - 2)
    this.endPage = Math.min(this.totalPage, this.startPage + 4)
    this.render()
  }

  render() {
    this.reset()

    if (this.currentPage == 0) {
      [this.firstElem, this.prevElem].forEach(button => {
        addClass(button, DISABLED_CLASS_NAME)
        button.toggleAttribute('disabled')
      })
    }

    if (this.currentPage == this.totalPage) {
      [this.nextElem, this.lastElem].forEach(button => {
        addClass(button, DISABLED_CLASS_NAME)
        button.toggleAttribute('disabled')
      })
    }

    for (let i = this.startPage; i <= this.endPage; i++) {
      const button = createButton(i + 1, this._buttonClass)
      addClass(button, NUMBER_CLASS_NAME)
      registerEvent(button, 'click', event => this.update(event, i, this.size))
      
      this.nextElem.before(button)
      if (i == this.currentPage) {
        button.setAttribute('aria-selected', true)
        button.toggleAttribute('disabled')
      }
    }
  }

  reset() {
    querySelector(`.${NUMBER_CLASS_NAME}`, this.root).forEach(elem => elem.remove())

    const arr = [this.firstElem, this.prevElem, this.nextElem, this.lastElem]
    arr.forEach(button => {
      removeClass(button, DISABLED_CLASS_NAME)
      button.removeAttribute('disabled')
    })

    if (this.totalPage < 1)
      hideElements(this.root)
    else
      showElements(this.root)
  }

  update(event, page, size) {
    if (!hasClass(event.target, DISABLED_CLASS_NAME) && this.onPaging) {
      this.onPaging({ page, size })

      if (hasValue(this._anchor)) {
        window.scrollTo(0, this._anchor)
      }
    }
  }

  _getSetting(el, key) {
    const defaultValue = DEFAULT_CONFIG[key]
    const configValue = BootstrapPagination.config[key]
    const attrValue = this.datasetHelper.getValue(el, key)

    return attrValue || configValue || defaultValue
  }
}

function createNav(className) {
  const nav = document.createElement('nav')
  isNotBlank(className) && addClass(nav, className)
  return nav
}

function createButton(text, className) {
  const a = document.createElement('button')
  isNotBlank(className) && addClass(a, className)

  if (isHtml(text)) {
    a.innerHTML = text
  } else {
    a.textContent = text
  }
  return a
}

function isHtml(input) {
  return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
}

export default BootstrapPagination
window && (window.BootstrapPagination = BootstrapPagination)

window.addEventListener('DOMContentLoaded', event => {
  registerMutationObserver(el => {
    if (!elementIs(el, 'div') || !hasClass(el, CLASS_NAME))
      return
    if (hasClass(el, INIT_CLASS_NAME))
      return

    new BootstrapPagination(el)
  })


  querySelector(`.${CLASS_NAME}`).forEach(el => {
    if (!hasClass(el, INIT_CLASS_NAME))
      new BootstrapPagination(el)
  })
}, { once: true })
